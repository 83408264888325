import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RemoveHTML from "../components/removeHtml"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Video from "../components/video"
// images
import appleStoreBtn from "../images/apple-store-btn.png"
import googlePlayBtn from "../images/google-play-btn.png"

const mobilePage = props => {
  const data = props.data.wpgraphql.page
  return (
    <Layout>
      <Seo
        title={"EnerBank Mobile App - Now Available"}
        description={"EnerBank USA"}
      />
      <Helmet>
        <body className="page-mobile" />
      </Helmet>
      {/* section-gap */}
      <section className="container">
        <div
          className="col-md-11 mx-auto text-center mb-md-5 py-3"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
        <div className="row d-flex align-items-end pb-4 video-row">
          <div className="embed-container">
            <Video
              videoSrcURL={
                "https://player.vimeo.com/video/854768105?h=632d4325ec"
              }
              allow="autoplay"
              videoTitle="Regions | EnerBank. America's home improvement lender of choice"
              videoWidth="100%"
              videoHeight="500"
            />
          </div>
        </div>
      </section>

      <div className="bg-blue mt-4 mt-md-5" id="paymentoptbenefits">
        <h2 className="text-center py-4 h1 font-weight-bold text-white">
          {data.mobile.easyWaysCustomersApplyHeading}
        </h2>
      </div>

      <div className="container my-5">
        <div className="row">
          {data.appmethod.paperlessmethod.map((item, i) => {
            return (
              <div
                className="quick-easy-item col-md-4 d-flex d-sm-block d-lg-flex"
                key={item.fieldGroupName + i}
              >
                <div className="box-circle icon regions-green content-center">
                  {(() =>
                    item?.sectionicon?.caption ? (
                      <FontAwesomeIcon
                        className="fa-icon"
                        icon={RemoveHTML(item?.sectionicon?.caption)}
                        preserveAspectRatio="none"
                      />
                    ) : (
                      <img
                        className="img-fluid"
                        src={item.sectionicon.sourceUrl}
                        alt={item.sectionicon.altText}
                      />
                    ))()}
                </div>
                <div className="flex-fill">
                  <span className="text-blue font-weight-normal mt-0 py-md-3">
                    {item.sectiontitle}
                  </span>
                  <p>{item.sectionContent}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="bg-blue mt-4 mt-md-4" id="features">
        <h2 className="text-center py-4 h1 font-weight-bold text-white">
          {
            data.mobile.mobileLoanApplicationFeatures
              .mobileLoanApplicationFeaturesHeading
          }
        </h2>
      </div>
      <div className="container pb-5">
        <div className="row app-features">
          <div className="col-md-6">
            <div className="ipad-col position-relative">
              <img
                src={
                  data.mobile.mobileLoanApplicationFeatures.mobileImage
                    .sourceUrl
                }
                alt={
                  data.mobile.mobileLoanApplicationFeatures.mobileImage.altText
                }
              />
            </div>
          </div>

          <div className="col-md-6 d-flex flex-column align-item-center">
            <div className="feat-col">
              {data.mobile.mobileLoanApplicationFeatures.applicationFeatures.map(
                (item, i) => {
                  const className = [
                    "get-started",
                    "custom-templates",
                    "support",
                  ]
                  return (
                    i < 3 && (
                      <div
                        className={
                          `list-icon-item left ` +
                          className[i] +
                          ` ${item?.featureIon?.caption ? "embed-icons" : null}`
                        }
                        key={item.fieldGroupName + i}
                      >
                        {(() =>
                          item?.featureIon?.caption ? (
                            <div className="box-circle icon regions-green content-center">
                              <FontAwesomeIcon
                                className="fa-icon"
                                icon={RemoveHTML(item?.featureIon?.caption)}
                                preserveAspectRatio="none"
                              />
                            </div>
                          ) : (
                            <style
                              dangerouslySetInnerHTML={{
                                __html: [
                                  "." + className[i] + ":after {",
                                  "background: rgba(77, 77, 77, 0.3) url(" +
                                    item.featureIon.sourceUrl +
                                    ") no-repeat center",
                                  "}",
                                ].join("\n"),
                              }}
                            ></style>
                          ))()}
                        <h4 className="text-blue">{item.featureTitle}</h4>
                        <p>{item.featureDescription}</p>
                      </div>
                    )
                  )
                }
              )}
            </div>

            <div className="feat-col">
              {data.mobile.mobileLoanApplicationFeatures.applicationFeatures.map(
                (item, i) => {
                  const className = [
                    "get-started",
                    "custom-templates",
                    "support",
                    "platforms",
                    "secure",
                    "simple",
                  ]
                  return (
                    i > 2 && (
                      <div
                        className={
                          `list-icon-item right ` +
                          className[i] +
                          ` ${item?.featureIon?.caption ? "embed-icons" : null}`
                        }
                        key={item.fieldGroupName + i}
                      >
                        {(() =>
                          item?.featureIon?.caption ? (
                            <div className="box-circle icon regions-green content-center">
                              <FontAwesomeIcon
                                className="fa-icon"
                                icon={RemoveHTML(item?.featureIon?.caption)}
                                preserveAspectRatio="none"
                              />
                            </div>
                          ) : (
                            <style
                              dangerouslySetInnerHTML={{
                                __html: [
                                  "." + className[i] + ":after {",
                                  "background: rgba(77, 77, 77, 0.3) url(" +
                                    item.featureIon.sourceUrl +
                                    ") no-repeat center",
                                  "}",
                                ].join("\n"),
                              }}
                            ></style>
                          ))()}
                        <h4 className="text-blue">{item.featureTitle}</h4>
                        <p>{item.featureDescription}</p>
                      </div>
                    )
                  )
                }
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 text-center mb-5">
        <h2 className="mb-3">Download Now</h2>
        <div id="download">
          <a
            href="https://apps.apple.com/us/app/enerbank-usa-contractor/id1146476917"
            target="_blank"
            rel="noopener noreferrer"
            className="mb-4 mb-md-0"
          >
            <img
              src={appleStoreBtn}
              alt="apple-store-btn"
              width="180"
              height="54"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.enerbank.loan_application&hl=en_US&gl=US"
            target="_blank"
            rel="noopener noreferrer"
            className="mb-4 mb-md-0"
          >
            <img
              src={googlePlayBtn}
              alt="google-play-btn"
              width="180"
              height="54"
            />
          </a>
        </div>
      </div>
    </Layout>
  )
}
export default mobilePage

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        appmethod {
          paperlessmethod {
            fieldGroupName
            sectionContent
            sectiontitle
            sectionicon {
              sourceUrl
              altText
              caption
            }
          }
        }
        video_section {
          video {
            fieldGroupName
            videoUrl
          }
        }
        mobile {
          easyWaysCustomersApplyHeading
          mobileLoanApplicationFeatures {
            mobileLoanApplicationFeaturesHeading
            mobileImage {
              altText
              sourceUrl
            }
            applicationFeatures {
              fieldGroupName
              featureDescription
              featureTitle
              featureIon {
                sourceUrl
                altText
                caption
              }
            }
          }
        }
      }
    }
  }
`
